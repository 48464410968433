a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline
}

body {
  font-family: Trebuchet MS1, Trebuchet MS, sans-serif;
  font-weight: 700;
  color: #000;
}

.wrapper {  
  width: 80vmin;  
  height: 50vmin;
  position: relative;
}

.image {
  width: 30%;
  float: right;
}

.image img {
  max-height: 90%;
  max-width: 90%;
  display: block;
  margin: auto;
}

.quote {
  width: 67%;
  float: left;
  font-size: 2.2vmin;  
}

.author {
  font-style: italic;
  text-align: right;
  font-size: 1.8vmin;
  margin-top: 5px;
}

.author a {
  color: inherit;
}

.bottom {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 2.5vmin; 
  position: absolute; 
	bottom: 0; 
}

.prev {
  background: url("assets/prev.png") repeat 0 0 transparent;
	left: 0; 
}

.next {
  background: url("assets/next.png") repeat 0 0 transparent;
	right: 0; 
}

.prev, .next {
  width: 5vmin;
  height: 5vmin;
  background-size: 100% 100%;
  border: none;
  position: absolute; 
	bottom: 0; 
}

span {
  display: block;
}
